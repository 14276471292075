import React from 'react';
import packageJSON from '../package.json';
import './App.css';
import {
  Container,
  Grid,
  Typography,
  Paper,
  Link,
  makeStyles,
} from '@material-ui/core';
import thumbnail1 from './assets/1.jpg';
import thumbnail2 from './assets/2.jpg';
import thumbnail3 from './assets/3.jpg';
import thumbnail4 from './assets/4.jpg';
import thumbnail5 from './assets/5.jpg';
import thumbnail6 from './assets/6.jpg';
import thumbnail7 from './assets/7.jpg';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(4),
    textAlign: 'center',
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
  },
  paper: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
}));

const items = [
  {
    name: "Saint Mark's Square (11th century)",
    urlHttp: 'http://venicecitytours.it/?v=venice_01',
    urlViewpoint: 'viewpoint://venicecitytours.it/?v=venice_01',
    urlViewpointIOS: 'venicecitytours/?v=venice_01',
    urlViewpointIOS2: 'venicecitytours:venicecitytours.it/?v=venice_01',
    thumbnail: thumbnail1,
  },
  {
    name: "Saint Mark's Small Square (1205)",
    urlHttp: 'http://venicecitytours.it/?v=venice_02',
    urlViewpoint: 'viewpoint://venicecitytours.it/?v=venice_02',
    urlViewpointIOS: 'venicecitytours/?v=venice_02',
    urlViewpointIOS2: 'venicecitytours:venicecitytours.it/?v=venice_02',
    thumbnail: thumbnail2,
  },
  {
    name: 'War Galley Interior After Lepanto (1572)',
    urlHttp: 'http://venicecitytours.it/?v=venice_03',
    urlViewpoint: 'viewpoint://venicecitytours.it/?v=venice_03',
    urlViewpointIOS: 'venicecitytours/?v=venice_03',
    urlViewpointIOS2: 'venicecitytours:venicecitytours.it/?v=venice_03',
    thumbnail: thumbnail3,
  },
  {
    name: 'Dock of San Marco Square After Lepanto (1572)',
    urlHttp: 'http://venicecitytours.it/?v=venice_04',
    urlViewpoint: 'viewpoint://venicecitytours.it/?v=venice_04',
    urlViewpointIOS: 'venicecitytours/?v=venice_04',
    urlViewpointIOS2: 'venicecitytours:venicecitytours.it/?v=venice_04',
    thumbnail: thumbnail4,
  },
  {
    name: 'Carnival on the Grand Canal (18th century)',
    urlHttp: 'http://venicecitytours.it/?v=venice_05',
    urlViewpoint: 'viewpoint://venicecitytours.it/?v=venice_05',
    urlViewpointIOS: 'venicecitytours/?v=venice_05',
    urlViewpointIOS2: 'venicecitytours:venicecitytours.it/?v=venice_05',
    thumbnail: thumbnail5,
  },
  {
    name: 'Rialto Bridge (1560)',
    urlHttp: 'http://venicecitytours.it/?v=venice_06',
    urlViewpoint: 'viewpoint://venicecitytours.it/?v=venice_06',
    urlViewpointIOS: 'venicecitytours/?v=venice_06',
    urlViewpointIOS2: 'venicecitytours:venicecitytours.it/?v=venice_06',
    thumbnail: thumbnail6,
  },
  {
    name: 'Rialto Market (1560)',
    urlHttp: 'http://venicecitytours.it/?v=venice_07',
    urlViewpoint: 'viewpoint://venicecitytours.it/?v=venice_07',
    urlViewpointIOS: 'venicecitytours/?v=venice_07',
    urlViewpointIOS2: 'venicecitytours:venicecitytours.it/?v=venice_07',
    thumbnail: thumbnail7,
  },
];

function App() {
  const classes = useStyles();

  return (
    <Container className={classes.container} maxWidth='xl'>
      <Typography variant='h2' gutterBottom>
        Venice City Tours (v{packageJSON.version})
      </Typography>

      <Grid container spacing={3}>
        {items.map((item, idx) => (
          <Grid item xs={12} sm={6} md={3} key={idx}>
            <Paper className={classes.paper}>
              <img src={item.thumbnail} alt='' width='100%' />

              <div className={classes.info}>
                <Typography variant='h6'>{item.name}</Typography>

                <Link href={item.urlHttp}>HTTP LINK</Link>

                <Link href={item.urlViewpoint}>VIEWPOINT ANDROID LINK</Link>

                <Link href={item.urlViewpointIOS2}>
                  VIEWPOINT ANDROID & IOS LINK
                </Link>
              </div>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default App;
